import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import * as ReactDOM from 'react-dom/client';
import { v4 } from 'uuid';
import App from './App';

if (process.env.MODE === 'production') {
  Sentry.init({
    dsn: 'https://ee3e5b605afea797e585d20ce4b0639a@o4504266120036352.ingest.sentry.io/4506644389756928',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'design.marketifyall.com'],
      }),
      new Sentry.Replay(),
    ],
  });
  let uid = window.localStorage.getItem('uid');
  if (!uid) {
    uid = v4();
    window.localStorage.setItem('uid', uid);
  }
  Sentry.setUser({ id: uid });
  FullStory.identify(uid);
}
// axios.defaults.baseURL = 'https://design.marketifyall.com';
axios.defaults.baseURL = 'https://editor-api.lidojs.com';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);
