import PlayCircleIcon from '@duyank/icons/regular/PlayCircle';
import { useEditor } from '@lidojs/design-editor';
import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useRef,
} from 'react';
import { downloadObjectAsJson } from '../utils/download';
import { UserButton, UserProfile } from '@clerk/clerk-react';
import { useEffect, useState } from 'react';


interface HeaderLayoutProps {
  openPreview: () => void;
}

const HeaderLayout: ForwardRefRenderFunction<
  HTMLDivElement,
  HeaderLayoutProps
> = ({ openPreview }, ref) => {
  const uploadRef = useRef<HTMLInputElement>(null);
  const { actions, query } = useEditor();
  const handleExport = () => {
    downloadObjectAsJson('file', query.serialize());
  };

  const handleImport = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function () {
        const fileContent = JSON.parse(reader.result as string);
        actions.setData(fileContent);
      };
      reader.readAsText(file);
      e.target.value = '';
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div
      ref={ref}
      css={{
        background: '#090f36',
        // padding: '0px 4px',
        paddingTop: 8,
        paddingBottom: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 900px)': {
          paddingTop: 2,
          paddingBottom: 2,
        },
      }}
    >
      <div
        css={{
          color: '#3d8eff',
          fontSize: 50,
        }}
      >
        <div
          css={{ color: 'white', height: 52, paddingTop: 0, paddingBottom: 0 }}
        >
          {isMobile ? <img css={{ maxHeight: '100%' }} src={'./assets/Marketifyall-small-logo.png'} /> : <img css={{ maxHeight: '100%' }} src={'./assets/Marketifyall-big-logo.png'} />}
        </div>
      </div>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <div
          css={{
            margin: '0 16px',
            cursor: 'pointer',
            color: '#fff',
            fontWeight: 700,
            ':hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={() => uploadRef.current?.click()}
        >
          <input
            ref={uploadRef}
            accept="application/json"
            css={{ display: 'none' }}
            type="file"
            onChange={handleImport}
          />
          Import
        </div>
        <div
          css={{
            margin: '0 16px',
            cursor: 'pointer',
            color: '#fff',
            fontWeight: 700,
            ':hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={() => handleExport()}
        >
          Export
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            color: '#fff',
            lineHeight: 1,
            background: '#3a3a4c',
            padding: '8px 14px',
            borderRadius: 8,
            cursor: 'pointer',
            ':hover': {
              background: 'rgba(58,58,76,0.5)',
            },
          }}
          onClick={openPreview}
        >
          <div css={{ marginRight: 4, fontSize: 20 }}>
            <PlayCircleIcon />
          </div>{' '}
          Preview
        </div>

        <div
          css={{
            margin: '0 16px',
            cursor: 'pointer',
            color: '#fff',
            fontWeight: 700,
            ':hover': {
              textDecoration: 'underline',
            },
          }}
        >
          <UserButton />
        </div>

      </div>
    </div>
  );
};

export default forwardRef(HeaderLayout);
