import { FC } from 'react';
import ReactGA from 'react-ga4';
import Test from './src/Test';
import { ClerkProvider, SignIn, SignedIn, SignedOut } from '@clerk/clerk-react';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-68BJDBYMLE');
}
const App: FC = () => {
  return (
    <ClerkProvider publishableKey={process.env.CLERK_FRONTEND_API}>
<SignedIn >
 <Test />
  </SignedIn>

  <SignedOut>
    <div style={{display:'flex', height:'100vh', justifyContent:'center', padding:'120px'}}>
    <SignIn />

    </div>
  </SignedOut>

  </ClerkProvider>
  )
};

export default App;
